<template>
    <v-card :max-width="maxWith" :height="height" :class='[cssClasses, "d-flex justify-center align-center border-radius-medium"]'>
        <v-card-title>
          <div class="d-flex justify-content-center justify-content-md-start w-100 mb-5">
            <img :src="imageUrl"/>
          </div >
          <div :style="{ height:'200px' }">
            <h5 class="text-h5 secondary--text font-weight-bold mb-2">{{ title }}</h5>
            <p class="font-size-14">
                {{ description }}
            </p>
          </div>
        </v-card-title>
    </v-card>
</template>
<script>
export default {
  name: 'AdvantagesCard',
  props: {
    description: { type: String, default: '' },
    title: { type: String, default: '' },
    cssClasses: { type: String, default: '' },
    maxWith: { type: String, default: '300' },
    width: { type: String, default: '100%' },
    imageUrl: { type: String },
    height: { type: String, default: '350' }
  }
};
</script>
